import { Location, NgIf } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ShippingAddress } from '@api/model/shippingAddress';
import { selectSelectedShippingAddressRef } from '@store/checkout/checkout.selectors';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { RouterService } from '@core/services/router.service';
import { selectUserProfileAddresses, selectUserProfileDefaultShippingAddressRef } from '@store/user/user.selectors';
import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { TranslateModule } from '@ngx-translate/core';
import { AddressEditComponent } from '../../../../../core/components/address-edit/address-edit.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective } from '@ngrx/component';
import { UserActions } from '@store/user/user.actions';
@Component({
  selector: 'pbb-edit-address-page',
  templateUrl: './edit-address-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LetDirective, MatButtonModule, RouterLink, MatIconModule, AddressEditComponent, TranslateModule],
})
export class EditAddressPageComponent extends UntilDestroy implements OnInit {
  addresses$: Observable<Array<ShippingAddress>> = this.store.select(selectUserProfileAddresses);
  defaultShippingAddressRef$: Observable<string> = this.store.select(selectUserProfileDefaultShippingAddressRef);
  address!: ShippingAddress;
  selectedShippingAddressRef?: string;
  continueToReview: boolean = false;
  isLoading: boolean = false;
  routeData$: Observable<any> = this.routerService.routeData;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private location: Location,
    private routerService: RouterService
  ) {
    super();
  }

  ngOnInit(): void {
    const stepData = this.route.snapshot.data['stepData'] || {};
    this.continueToReview = stepData['continueToReview'] || false;
    const addressIndex = parseInt(this.route.snapshot.paramMap.get('id')!);
    if (addressIndex || addressIndex === 0) {
      this.addresses$.pipe(take(1)).subscribe((addresses) => {
        this.address = {
          ...addresses[addressIndex],
          street_name: `${[addresses[addressIndex]?.street_number, addresses[addressIndex]?.street_name]
            .join(' ')
            .trim()}`,
          street_number: ' ',
        };
      });
    }
    this.store
      .select(selectSelectedShippingAddressRef)
      .pipe(take(1))
      .subscribe((ref) => {
        this.selectedShippingAddressRef = ref;
      });
  }

  saveShippingAddress(shippingAddress: ShippingAddress) {
    this.location.back();

    // active loading stage
    this.store.dispatch(UserActions.modifyShippingAddressList());

    // map input data
    shippingAddress.postal_code = shippingAddress.postal_code.toUpperCase();
    if (shippingAddress.shipping_address_ref) {
      this.store.dispatch(UserActions.updateShippingAddressStart({ shippingAddress }));
    } else {
      this.store.dispatch(UserActions.addShippingAddressStart({ shippingAddress }));
    }
  }
}
