import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const PilotRedirectGuard: CanActivateFn = () => {
  const window = inject(Window);
  const router = inject(Router);
  const url = window.location.href;
  if ((url.includes('.pilot.') || url.includes('-ui.')) && !url.includes('/pilot')) {
    return router.parseUrl('/pilot');
  } else {
    return router.parseUrl('/user-portal');
  }
};
