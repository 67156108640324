import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { UserRegistrationStatus } from '@api/index';
import { OrderPayment } from '@api/model/orderPayment';
import { ShippingAddress } from '@api/model/shippingAddress';
import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { MenuAction } from '@core/components/menu/menu.component';
import { NOTICE_TYPE } from '@core/constants';
import { SNAME } from '@core/constants/storage';
import { NoticeService } from '@core/services/notice.service';
import { checkAddressValiditiy } from '@core/utils';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { setShippingAddress, updateConsentGrant } from '@store/checkout/checkout.actions';
import {
  selectIsShippingRequired,
  selectSelectedShippingAddressRef,
  selectShippingCost,
} from '@store/checkout/checkout.selectors';
import {
  selectRegisterStatus,
  selectUserProfileAddresses,
  selectUserProfileDefaultShippingAddressRef,
} from '@store/user/user.selectors';
import { FinancialInstitution, LoaderState } from '@store/core/core.interface';
import { selectMaxShippingAddress, selectSelectedFI } from '@store/core/core.selectors';
import { Observable, combineLatest, map, take } from 'rxjs';
import { UserActions } from '@store/user/user.actions';
import { AddressListComponent } from '@core/components/address-list/address-list.component';
import { AddressSuggestionComponent } from '@core/components/address-suggestion/address-suggestion.component';
import { PbbNoticeComponent } from '@core/components/pbb-notice/pbb-notice.component';

@Component({
  selector: 'pbb-view-address-page',
  templateUrl: './view-address-page.component.html',
  styleUrls: ['./view-address-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LetDirective,
    NgIf,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    AddressSuggestionComponent,
    PbbNoticeComponent,
    MatToolbarModule,
    AddressListComponent,
    TranslateModule,
  ],
})
export class ViewAddressPageComponent extends UntilDestroy implements OnInit, OnDestroy {
  selectedFI$: Observable<FinancialInstitution> = this.store.select(selectSelectedFI);
  selectedShippingAddressRef$: Observable<string> = this.store.select(selectSelectedShippingAddressRef);
  defaultShippingAddressRef$: Observable<string> = this.store.select(selectUserProfileDefaultShippingAddressRef);
  addresses$: Observable<Array<ShippingAddress>> = this.store.select(selectUserProfileAddresses);
  shippingCost$: Observable<LoaderState<OrderPayment> | undefined> = this.store.select(selectShippingCost);
  shippingRequired$: Observable<boolean> = this.store.select(selectIsShippingRequired);

  selectedAddressIndex: number = 0;
  isGuestCheckout: boolean = false;
  continueToReview: boolean = false;
  noticeType = NOTICE_TYPE;

  maxAddress$ = this.store.select(selectMaxShippingAddress);
  isValidSelectedAddress$: Observable<boolean> = combineLatest([
    this.addresses$,
    this.selectedShippingAddressRef$,
    this.shippingRequired$,
    this.shippingCost$,
  ]).pipe(
    map(([addresses, shippingAddressRef, shippingRequired, shippingCost]) => {
      this.selectedAddressIndex = addresses.findIndex((address) => address.shipping_address_ref === shippingAddressRef);
      if (shippingRequired) {
        return checkAddressValiditiy(addresses[this.selectedAddressIndex]);
      }

      return true;
    })
  );
  registerStatus$ = this.store.select(selectRegisterStatus);
  RegisteredUser = UserRegistrationStatus.Registered;
  isAddressChanged!: boolean;
  constructor(private readonly store: Store, private readonly route: ActivatedRoute, private ns: NoticeService) {
    super();
  }

  ngOnInit(): void {
    this.isAddressChanged = sessionStorage.getItem(SNAME.ADDRESS_CHANGED) === 'true';
    const stepData = this.route.snapshot.data['stepData'] || {};
    this.isGuestCheckout = stepData['isGuestCheckout'] || false;
    this.continueToReview = stepData['continueToReview'] || false;
  }

  setShippingAddress(shippingAddress: ShippingAddress) {
    this.store
      .select(selectIsShippingRequired)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(setShippingAddress({ shippingAddress, update: res }));
        } else {
          this.store.dispatch(
            UserActions.setUserShippingAddressRef({ shippingAddressRef: shippingAddress.shipping_address_ref! })
          );
        }
      });
  }

  setMenuAction(menuAction: MenuAction) {
    switch (menuAction.action) {
      case 'default':
        this.store.dispatch(
          UserActions.setDefaultShippingAddressStart({ shippingAddressRef: menuAction?.itemRef || '' })
        );
        break;
      case 'remove':
        this.store.dispatch(UserActions.deleteShippingAddressStart({ addressIndex: menuAction?.itemIndex || 0 }));
        break;
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.addresses$.pipe(take(1)).subscribe((addresses) => {
      this.store.dispatch(
        updateConsentGrant({
          consentGrantPayload: {
            shipping_address: addresses[this.selectedAddressIndex],
          },
        })
      );
    });
  }
}
