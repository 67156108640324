import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { FiSelectionClass } from '@core/classes/fi-selection';
import { MatDialog } from '@angular/material/dialog';
import { ENV_CONFIG } from '@core/interfaces/configs';
import { WindowService } from '@core/services/window.service';
import { ENVCONFIG } from '@core/tokens/configs';
import { OidcErrorHandlerMixin } from '@core/utils';
import { clearOIDCError } from '@store/core/core.actions';
import { selectUserProfileRef } from '@store/user/user.selectors';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FiSelectionComponent } from '../../../../../core/components/fi-selection/fi-selection.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'pbb-link-another-page',
  templateUrl: './link-another-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LetDirective, MatButtonModule, RouterLink, MatIconModule, FiSelectionComponent, TranslateModule],
})

// extend FI selection Class if selectedFI are not to be filtered
export class LinkAnotherPageComponent extends OidcErrorHandlerMixin(FiSelectionClass) implements OnInit, OnDestroy {
  override standalone: boolean = false;
  override redirectUrl: string = '/checkout/registered/orderReview/returnUser/paymentMethod';
  override rus = false;
  override authentication = false;

  userProfileRef$ = this.store.select(selectUserProfileRef);
  constructor(
    store: Store,
    dialog: MatDialog,
    window: Window,
    merchantWindowService: WindowService,
    @Inject(ENVCONFIG) envConfig: ENV_CONFIG,
    private readonly router: Router
  ) {
    super(store, dialog, window, merchantWindowService, envConfig);
  }

  ngOnInit(): void {
    if (this.router.url.includes('returnUser')) {
      this.redirectUrl = '/checkout/registered/orderReview/returnUser/paymentMethod';
    } else {
      this.redirectUrl = '/checkout/registered/orderReview/paymentMethod';
    }
  }
  override ngOnDestroy(): void {
    this.store.dispatch(clearOIDCError());
    super.ngOnDestroy();
  }
}
