<div class="content_main-container" *ngrxLet="addresses$ as addresses">
  <div style="display: flex; align-items: center">
    <button
      *ngIf="continueToReview"
      mat-icon-button
      class="back-btn"
      [attr.aria-label]="'ARIA_LABEL.BACK' | translate"
      id="header_back-btn"
      routerLink=".."
    >
      <mat-icon>arrow_back_outlined</mat-icon>
    </button>
    <h1 id="checkout_view_address_title">{{ 'COMMON.VIEW_ADDRESS.TITLE' | translate }}</h1>
  </div>

  <small class="mat-body-2 mt-11 block" id="user-portal_view_address_subtitle">{{
    'COMMON.VIEW_ADDRESS.SUBTITLE' | translate
  }}</small>
  <ng-container *ngrxLet="registerStatus$ as registerStatus">
    <ng-container *ngIf="registerStatus === RegisteredUser ? false : !isAddressChanged">
      <pbb-address-suggestion *ngrxLet="selectedFI$ as selectedFI" [financialInstitution]="selectedFI">
      </pbb-address-suggestion>
    </ng-container>
  </ng-container>
  <ng-container *ngrxLet="isValidSelectedAddress$ as isValidSelectedAddress">
    <ng-container *ngrxLet="shippingCost$ as shippingCost">
      <pbb-notice
        [type]="noticeType.ERROR"
        *ngIf="shippingCost?.data?.shipping_cost !== null"
        title="{{ 'COMMON.ERROR.SHIPPING.MESSAGE_ALT' | translate }}"
      ></pbb-notice>
    </ng-container>

    <ng-container *ngIf="!isValidSelectedAddress">
      <pbb-notice
        id="incomplete-address-error-notice"
        [type]="noticeType.ERROR"
        title="{{ 'COMMON.ERROR.SHIPPING.INCOMPLETE_ADDRESS' | translate }}"
      ></pbb-notice>
    </ng-container>
    <ng-container *ngrxLet="maxAddress$ as maxAddress">
      <div *ngIf="!(maxAddress === -1 || maxAddress > (addresses?.length || 0))">
        <mat-toolbar class="warning-toolbar" id="user-portal_address-limit-info">
          <mat-icon class="icon-smd mr-12">warning_amber</mat-icon>
          <span class="button-2 preline">{{
            'COMMON.MAX_SHIPPING_ADDRESS_LIMIT' | translate : { shippingAddressLimit: maxAddress }
          }}</span>
        </mat-toolbar>
      </div>
    </ng-container>
    <ng-container *ngrxLet="selectedShippingAddressRef$ as selectedShippingAddressRef">
      <ng-container *ngrxLet="defaultShippingAddressRef$ as defaultShippingAddressRef">
        <ng-container>
          <pbb-address-list
            [addresses]="addresses"
            [showMenuButton]="!isGuestCheckout"
            (clickShippingAddress$)="setShippingAddress($event)"
            [selectedShippingAddressRef]="selectedShippingAddressRef"
            [defaultShippingAddressRef]="defaultShippingAddressRef"
            (menuAction)="setMenuAction($event)"
          >
          </pbb-address-list>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="content_main-button-list">
      <ng-container *ngIf="!isGuestCheckout; else guestCheckout">
        <button
          routerLink=".."
          mat-flat-button
          color="primary"
          id="checkout_view_address_button_continue"
          [disabled]="!isValidSelectedAddress"
        >
          {{ 'COMMON.BUTTON.CONTINUE' | translate }}
        </button>

        <ng-container *ngrxLet="maxAddress$ as maxAddress">
          <button
            mat-flat-button
            [disabled]="!(maxAddress === -1 || maxAddress > (addresses?.length || 0))"
            color="accent"
            id="checkout_view_address_button_add_address"
            [routerLink]="['./add']"
          >
            {{ 'COMMON.VIEW_ADDRESS.BUTTON_ADD_ADDRESS' | translate }}
          </button>
        </ng-container>
      </ng-container>
      <ng-template #guestCheckout>
        <button
          routerLink=".."
          mat-flat-button
          color="primary"
          id="checkout_view_address_button_continue"
          [disabled]="!isValidSelectedAddress"
        >
          {{ 'COMMON.BUTTON.CONTINUE' | translate }}
        </button>

        <button
          mat-flat-button
          color="accent"
          id="checkout_view_address_button_change_address"
          [routerLink]="['./edit/' + selectedAddressIndex]"
        >
          {{ 'COMMON.VIEW_ADDRESS.BUTTON_CHANGE_ADDRESS' | translate }}
        </button>
      </ng-template>
    </div>
  </ng-container>
</div>
